import {
    AvailableOrderTransaction,
    IDfaFront,
    ResponseDfa,
    TablePaginationParam,
} from '@dltru/dfa-models'
import { createAction, createSlice } from '@reduxjs/toolkit'

interface IInitialState {
    isLoading: boolean
    data: IDfaFront[]
    error?: string
}

const initialState: IInitialState = { error: '', isLoading: false, data: [] as IDfaFront[] }
const dfaListSlice = createSlice({
    name: 'dfaList',
    initialState,
    reducers: {
        getDfaList: (state) => {
            return state
        },
        updateDfaList: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateDfaById: (state, action) => {
            state.data[action.payload.dfa.id] = action.payload.dfa
        },
        dropDfaList: () => {
            return { ...initialState }
        },
        updateErrorDfaList: (state, action) => {
            state.error = action.payload
        },
        updateLoadingDfaList: (state, action) => {
            state.isLoading = action.payload
        },
    },
})

export const loadDfas = createAction<
    TablePaginationParam<AvailableOrderTransaction> & Partial<ResponseDfa>
>('dfaList/loadDfas')
export const loadDfasEmission = createAction<
    TablePaginationParam<AvailableOrderTransaction> & Partial<ResponseDfa>
>('dfaList/loadDfasEmission')

export const {
    updateDfaList,
    getDfaList,
    dropDfaList,
    updateDfaById,
    updateErrorDfaList,
    updateLoadingDfaList,
} = dfaListSlice.actions
export default dfaListSlice.reducer
