import { ExternalRateIndex, IDfaBack, dfaBackToFront } from '@dltru/dfa-models'
import { StrictEffect } from 'redux-saga/effects'
import { put, select, takeLatest } from 'typed-redux-saga'

import { loadDfaRates } from '@store/dfa/rates'
import { dfaRatesSelector } from '@store/dfa/rates/selectors'

import api from '@services/api'

import { loadDfas, loadDfasEmission, updateDfaList } from './index'

function* handleLoadDfas({ payload }: ReturnType<typeof loadDfas>) {
    try {
        yield* put(updateDfaList({ error: '', isLoading: true }))
        yield* put(loadDfaRates())
        const { data, error } = yield api.lib.getDfaList<{ items: IDfaBack[] }>({
            ...payload,
        })

        if (error) {
            throw error
        }

        const rateData = yield* select(dfaRatesSelector.selectDfaRates)
        data.items = data.items.map((dfa: IDfaBack) =>
            dfaBackToFront(
                dfa,
                undefined,
                undefined,
                rateData[dfa.price_source || ExternalRateIndex.GLDRUB_TOM],
            ),
        )

        yield* put(updateDfaList({ isLoading: false, data }))
    } catch (error) {
        yield* put(updateDfaList({ error, isLoading: false, data: [] }))
    }
}

function* handleLoadDfasEmission({ payload }: ReturnType<typeof loadDfasEmission>) {
    try {
        yield* put(updateDfaList({ error: '', isLoading: true }))
        yield* put(loadDfaRates())
        const { data, error } = yield api.lib.getDfaList<{ items: IDfaBack[] }>(payload)

        if (error) {
            throw error
        }
        const rateData = yield* select(dfaRatesSelector.selectDfaRates)
        data.items = data.items.map((dfa: IDfaBack) =>
            dfaBackToFront(
                dfa,
                undefined,
                undefined,
                rateData[dfa.price_source || ExternalRateIndex.GLDRUB_TOM],
            ),
        )

        yield* put(updateDfaList({ isLoading: false, data }))
    } catch (error) {
        yield* put(updateDfaList({ error, isLoading: false, data: [] }))
    }
}

export function* watchDfaList(): Generator<StrictEffect> {
    yield* takeLatest(loadDfas.type, handleLoadDfas)
    yield* takeLatest(loadDfasEmission.type, handleLoadDfasEmission)
}
