import { createAction, createSlice } from '@reduxjs/toolkit'

import { IDataPageRequest } from '@store/dfa/types'

import { initialStateOwnersList as initialState } from '../resources'

const dfaOwnersListSlice = createSlice({
    name: 'dfaOwnersList',
    initialState,
    reducers: {
        setDfaOwnersPage: (state, action) => {
            return {
                ...state,
                isLoading: false,
                error: '',
                data: action.payload.data,
                sort: {
                    by: action.payload.sort_by,
                    type: action.payload.order_by,
                },
                page: action.payload.page,
                limit: action.payload.limit,
            }
        },
        setDfaOwnersLoading: (state) => {
            return { ...state, isLoading: true, data: [], error: '' }
        },
        setDfaOwnersError: (state, action) => {
            return { ...state, error: action.payload, isLoading: false, data: [] }
        },
    },
})

interface IDfaOwnersPageRequest extends IDataPageRequest {
    dfaId: number
    withPayoffAgreements: boolean
}

export const getPage = createAction<IDfaOwnersPageRequest>('dfaOwnersList/getPage')

export const { setDfaOwnersPage, setDfaOwnersLoading, setDfaOwnersError } =
    dfaOwnersListSlice.actions

export default dfaOwnersListSlice.reducer
