import { IDfaFront } from '@dltru/dfa-models'
import { createSelector } from 'reselect'

import { authSelector } from '@store/auth/selectors'

import IAppState from '../../states'

const findDfaById =
    (dfaId: string) =>
    (state: IAppState): IDfaFront | undefined => {
        return state.dfaList.data?.items?.find((value) => value.id === dfaId)
    }
const selectIsLoading = (state: IAppState) => state.dfaList.isLoading
const selectList = (state: IAppState) => state.dfaList.data
const getMyDfas = createSelector(
    selectList,
    authSelector.selectUserUid,
    (dfas, authId) => dfas?.items?.filter((dfa) => dfa.emitter_id === authId) ?? [],
)

export const dfaListSelectors = {
    findDfaById,
    selectIsLoading,
    selectList,
    getMyDfas,
}
