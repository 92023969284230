import {
    CancelTransferPayload,
    ICalcFeeRequestProps,
    IDFAOwnershipTransferRequestClient,
    ITransferDeal,
    ITransferOrderShort,
    ProfileType,
    TransferDfaInit,
} from '@dltru/dfa-models'
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit'

import { EntityState, IDFAOwnershipTransferRequestIdentificator } from '@store/dfa/types'

import { initialState as initialStateDfa } from '../resources'

type OfferType = { dfa: typeof initialStateDfa; price: number; amount_dfa: number }
type BuyerDataType = { type: ProfileType; is_qualified: boolean; quota: number }
type InitialStateType = EntityState<TransferDfaInit | ITransferOrderShort | null> & {
    buyerData: BuyerDataType
    offerentOffer: Partial<OfferType>
    accepterOffer: Partial<OfferType>
    deals: ITransferDeal[]
    dealsIsLoading: boolean
    fee: number | undefined
}
const initialState: InitialStateType = {
    isLoading: false,
    dealsIsLoading: false,
    data: null,
    error: '',
    buyerData: {} as BuyerDataType,
    offerentOffer: { dfa: initialStateDfa, price: 0, amount_dfa: 0 },
    accepterOffer: { dfa: initialStateDfa, price: 0, amount_dfa: 0 },
    deals: [],
    fee: undefined,
}

export const dfaOwnershipTransferSlice = createSlice({
    name: 'dfaOwnershipTransfer',
    initialState,
    reducers: {
        setDFAOwnershipTransfer: (state, action) => {
            state.isLoading = false
            state.data = action.payload.data
            state.offerentOffer.price = action.payload.data?.seller_asset_data?.total_price || 0
            state.accepterOffer.price = action.payload.data?.buyer_asset_data?.total_price || 0
            state.error = ''
        },
        setDFAOwnershipTransferLoading: (state, action) => {
            state.isLoading = action.payload
            state.error = ''
        },
        setDFAOwnershipTransferError: (state, action) => {
            state.isLoading = false
            state.error = action.payload.data
        },
        setBuyerDataTransfer: (state, action: PayloadAction<Partial<BuyerDataType>>) => {
            state.buyerData = { ...state.buyerData, ...action.payload }
        },
        clearDFAOwnershipBuyerData: (state) => {
            state.buyerData = {} as BuyerDataType
        },

        setOfferentOffer: (state, action: PayloadAction<Partial<OfferType>>) => {
            state.offerentOffer = { ...state.offerentOffer, ...action.payload }
        },

        setAccepterOffer: (state, action: PayloadAction<Partial<OfferType>>) => {
            state.accepterOffer = { ...state.accepterOffer, ...action.payload }
        },
        setTransferDealsIsLoading: (state, action: PayloadAction<boolean>) => {
            state.dealsIsLoading = action.payload
        },
        setTransferDeals: (state, action: PayloadAction<ITransferDeal[]>) => {
            state.deals = action.payload
        },

        clearOwnershipTransfer: (state) => {
            state.offerentOffer = initialState.offerentOffer
            state.accepterOffer = initialState.accepterOffer
            state.data = initialState.data
            state.deals = initialState.deals
            state.fee = undefined
        },
        setFee: (state, action) => {
            state.fee = action.payload
        },
    },
})

export const getDFAOwnershipTransfer = createAction<IDFAOwnershipTransferRequestIdentificator>(
    'dfaOwnershipTransfer/getDFAOwnershipTransfer',
)

export const acceptDFAOwnershipTransfer = createAction<IDFAOwnershipTransferRequestIdentificator>(
    'dfaOwnershipTransfer/acceptDFAOwnershipTransfer',
)

export const acceptDFAOwnershipTransferPrefly = createAction<IDFAOwnershipTransferRequestIdentificator>(
    'dfaOwnershipTransfer/acceptDFAOwnershipTransferPrefly',
)

export const executeDFATransfer = createAction<IDFAOwnershipTransferRequestIdentificator>(
    'dfaOwnershipTransfer/executeDFATransfer',
)

export const executeDFATransferPrefly = createAction<IDFAOwnershipTransferRequestIdentificator>(
    'dfaOwnershipTransfer/executeDFATransferPrefly',
)

type CreateDFAOwnershipTransferPayload = {
    data: IDFAOwnershipTransferRequestClient
    callback?: () => void
}

export const createDFAOwnershipTransferRequestAction =
    createAction<CreateDFAOwnershipTransferPayload>(
        'dfaOwnershipTransfer/createDFAOwnershipTransferRequest',
)

export const createDFAOwnershipTransferRequestActionPrefly =
    createAction<CreateDFAOwnershipTransferPayload>(
        'dfaOwnershipTransfer/createDFAOwnershipTransferRequestPrefly',
    )

export const checkLimitRecipientTransferAction = createAction<string>(
    'dfaOwnershipTransfer/checkLimitRecipientTransferAction',
)

export const transferCancelByOwner = createAction<CancelTransferPayload>(
    'dfaOwnershipTransfer/transferCancelByOwner',
)

export const transferCancelByOwnerPrefly = createAction<CancelTransferPayload>(
    'dfaOwnershipTransfer/transferCancelByOwnerPrefly',
)

export const transferCancelByRecipient = createAction<number>(
    'dfaOwnershipTransfer/transferCancelByRecipient',
)

export const getOfferentDFA = createAction<number>('dfaOwnershipTransfer/getOfferentDFA')

export const getAcceptorDFA = createAction<number>('dfaOwnershipTransfer/getAcceptorDFA')

export const getTransferDeals = createAction<unknown>('dfaOwnershipTransfer/getTransferDeals') // ???
export const getTransferFee = createAction<ICalcFeeRequestProps>(
    'dfaOwnershipTransfer/getTransferFee',
)

export const {
    setDFAOwnershipTransfer,
    setDFAOwnershipTransferLoading,
    setDFAOwnershipTransferError,
    setOfferentOffer,
    setAccepterOffer,
    setTransferDealsIsLoading,
    setTransferDeals,
    clearOwnershipTransfer,
    setFee,
} = dfaOwnershipTransferSlice.actions

export default dfaOwnershipTransferSlice.reducer
